import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>Loader Logo</title>
    <g>
      <g id="B" transform="translate(30.000000, 24.000000)">
        <path
          d="M 5.981 45.972 L 7.417 45.972 L 7.417 8.682 A 11.359 11.359 0 0 1 7.704 6.096 A 10.184 10.184 0 0 1 7.93 5.281 Q 8.442 3.691 9.314 2.512 A 6.816 6.816 0 0 1 10.658 1.133 A 6.157 6.157 0 0 1 11.365 0.667 Q 12.544 0 13.877 0 L 24.814 0 L 24.814 5.435 L 15.552 5.435 L 15.552 37.598 A 11.103 11.103 0 0 1 15.395 39.5 A 9.053 9.053 0 0 1 15.056 40.845 A 12.048 12.048 0 0 1 14.462 42.3 A 9.42 9.42 0 0 1 13.826 43.408 Q 13.091 44.502 12.202 45.168 Q 11.527 45.675 10.911 45.875 A 2.775 2.775 0 0 1 10.527 45.972 L 30.557 45.972 L 30.557 38.452 L 37.769 38.452 L 37.769 46.006 A 5.188 5.188 0 0 1 37.256 48.296 A 6.227 6.227 0 0 1 35.906 50.142 Q 35.068 50.928 33.992 51.372 Q 32.915 51.816 31.787 51.816 L 0 51.816 Q 0.103 50.654 0.632 49.578 A 6.86 6.86 0 0 1 1.697 47.994 A 6.355 6.355 0 0 1 1.965 47.715 A 6.562 6.562 0 0 1 3.794 46.45 A 5.11 5.11 0 0 1 5.981 45.972 Z"
          fill="currentColor"
        />
      </g>
      <path
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M 50, 5
                  L 11, 27
                  L 11, 72
                  L 50, 95
                  L 89, 73
                  L 89, 28 z"
      />
    </g>
  </svg>
);

export default IconLoader;
